<template>
  <div class="jackpot">
    <Header v-if="!$route.query.platform">世界杯奖池</Header>
    <div
      class="container"
      :style="{
        top: $route.query.platform ? '10px' : '60px',
      }"
    >
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <div class="top child">
          <img :src="info.blind_url" alt="" />
          <div class="pic">
            奖池金额：¥ <span>{{ info.blind_money_use }}</span>
          </div>
          <div class="title">
            当前球队盲盒销售额：￥<span>{{ info.blind_money_all }}</span>
          </div>
          <div class="subTitle">参与瓜分份数：{{ info.blind_number }}</div>
        </div>
        <div class="bottom child">
          <img :src="info.charm_url" alt="" />
          <div class="pic">
            奖池金额：¥ <span>{{ info.charm_money_use }}</span>
          </div>
          <div class="title">
            当前锦鲤盲盒销售额：￥<span>{{ info.charm_money_all }}</span>
          </div>
          <div class="subTitle">参与瓜分份数：{{ info.charm_number }}</div>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
export default {
  name: 'jackpot',
  data() {
    return {
      info: {},
      refreshing: false,
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      this.$api.partition().then((e) => {
        if (e.code === 0) {
          this.info = e.data;
          setTimeout(() => {
            this.refreshing = false;
          }, 1000);
        } else {
          this.$toast(e.msg);
        }
      });
    },
    onRefresh() {
      this.getInfo();
    },
  },
};
</script>

<style scoped lang="less">
.jackpot {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(
    34deg,
    #e4ede8,
    #e0f7ff,
    #fff5ef,
    #d0e4ff,
    #d2f0f8,
    #dff5e8
  );
}
.container {
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}
.child {
  width: 245px;
  box-shadow: 0px 4px 14px 2px rgba(1, 15, 45, 0.05);
  border-radius: 10px;
  margin: 0 auto 20px;
  text-align: center;
  padding-bottom: 10px;
  overflow: hidden;
  img {
    width: 100%;
    height: 245px;
    vertical-align: middle;
  }
  .pic {
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #eea134;
    line-height: 20px;
    margin: 7px 0 10px;
    span {
      font-size: 17px;
      font-weight: bold;
    }
  }
  .title {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    span {
      font-weight: bold;
    }
  }
  .subTitle {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
    margin: 10px 0 0;
  }
}
</style>
